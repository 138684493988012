<template>
   <a-card :bordered='false'>
      <header slot="title" class="tc-header">充值/扣减</header>
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
         <a-form-model-item label="用户手机号" prop="mobile">
            <a-input :maxLength='11' loading @change='autoComplete("mobile")' v-model="form.mobile" allowClear placeholder='请输入用户手机号'/>
         </a-form-model-item>
         <a-form-model-item label="用户ID" prop="unionId">
            <a-input @change='autoComplete("unionId")' v-model="form.unionId" allowClear placeholder='请输入用户ID'/>
         </a-form-model-item>
         <a-form-model-item label='充值/扣减类型' :wrapper-col='{span: 4}' prop='type'>
            <a-select allowClear placeholder='请选择' v-model="form.type">
               <a-select-option :value="1">小汇豆</a-select-option>
               <a-select-option :value="2">积分</a-select-option>
            </a-select>
         </a-form-model-item>
         <a-form-model-item label='积分类型' :wrapper-col='{span: 4}' v-if="form.type === 2" prop='jifenType'>
            <a-select allowClear placeholder='请选择' v-model="form.jifenType">
               <a-select-option :value="1">激活积分</a-select-option>
               <!-- <a-select-option :value="2">销售积分</a-select-option> -->
               <a-select-option :value="3">推广积分</a-select-option>
            </a-select>
         </a-form-model-item>
         <a-form-model-item label='名目' :wrapper-col='{span: 4}' prop='moduleType'>
            <a-select allowClear placeholder='请选择' v-model="form.moduleType">
               <a-select-option :value="1">系统发放</a-select-option>
            </a-select>
         </a-form-model-item>
         <a-form-model-item label='数值' prop='num'>
            <a-input-number style="width:120px" placeholder='请输入数值' :step="0.01" :precision='2' v-model="form.num" />
            <span style="margin-left:12px;color:red">数值正数是充值,负数是扣减</span>
         </a-form-model-item>
         <a-form-model-item label='备注' prop='remark'>
            <a-textarea  v-model="form.remark" placeholder="请输入备注" :autoSize='{minRows: 3}' allowClear />
         </a-form-model-item>
         <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submit">确认</a-button>
            <a-button style="margin-left: 12px;" @click="$router.back()">返回列表</a-button>
         </a-form-model-item>
      </a-form-model>
   </a-card>
</template>

<script>
import api from '@/api'
import util from '@/libs/util'
export default {
   name: 'RechargeOptions',
   data () {
      return {
         labelCol: { span: 4 },
         wrapperCol: { span: 8 },
         form: {
            mobile: undefined,
            unionId: undefined,
            num: undefined,
            type: undefined,
            jifenType: undefined,
            moduleType: undefined,
            remark: undefined,
            userId: undefined
         },
         rules: {
            mobile: [
               {required: true, message: '请输入用户手机号'},
               {len: 11, message: '请输入11位手机号', trigger: 'blur'}
            ],
            unionId: {required:true, message:'请输入用户ID'},
            num: {required:true, message:'请输入数值'},
            type: {required:true, message:'请选择充值/扣减类型'},
            jifenType: {required:true, message:'请选择积分类型'},
            moduleType: {required:true, message:'请选择名目'}
         },
         autoComplete: util.debounce(this.fillText, 500),
         autoCompleteLoading: false,
         timer: null
      }
   },
   watch: {
      'form.unionId' (val) {
         if (val) {
            this.form.unionId = val.replace(/\D/g, '');
         }
      }
   },
   activated () {
      this.$refs.form.resetFields()
   },
   methods: {
      submit () {
         this.$refs.form.validate().then(() => {
            let {mobile, unionId, num, type, jifenType, moduleType } = this.form
            let desc = ''
            if (type === 1) {
               desc = '小汇豆'
            } else if (type === 2) {
               desc = ['','激活积分', '销售积分', '推广积分'][jifenType]
            }
            this.$confirm({
               content: `确认给用户ID${unionId} 手机号${mobile} 充值${num} ${desc}?`,
               onOk: () => new Promise((resolve, reject) => {
                  this.timer = setInterval(() => {
                     if (!this.autoCompleteLoading) {
                        clearInterval(this.timer)
                        this.$nextTick(() => {
                           let p = {
                              account: this.$store.state.userInfo.account,
                              ...this.form,
                              jifenType: type === 2 ? jifenType: undefined,
                           }
                           api.marketManage.operateBonus(p).then(res => {
                              if (res.code === '00000') {
                                 this.$message.success('操作成功')
                                 this.$refs.form.resetFields()
                                 resolve()
                              } else {
                                 this.$message.error(res.msg)
                                 reject()
                              }
                           })
                        })
                     }
                  }, 300)
                     
                  
               }).catch(() => {
               })
                  
            })
         })
      },
      /* 
         输入手机号自动填充用户ID
         输入用户ID自动填充手机号
      */ 
      fillText (type) {
         let p = {
            pageNum: 1,
            pageSize: 1
         }
         let val = this.form[type]
         if (val === 'mobile' && val.length !== 11) return 
         if (val === '' || val === undefined) return 
         p[type] = val
         this.autoCompleteLoading = true
         api.shopkeeperManage.userList(p).then(res => {
            if (res.code === '00000') {
               if (type === 'unionId') this.form.mobile = res.data?.dataList[0]?.mobile
               if (type === 'mobile') this.form.unionId = res.data?.dataList[0]?.unionId
               this.form['userId'] = res.data?.dataList[0]?.id
            }
         }).finally(() => this.autoCompleteLoading = false)
      },
   }
}
</script>

<style lang="less" scoped>
   /deep/.tc-header {
      font-size: 18px;
      font-weight: 550;
   }
</style>
